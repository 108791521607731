import {
  mdiFactory,
  mdiAccountSupervisor,
  mdiEmoticonHappyOutline,
  mdiFileTree,
  mdiHeadSnowflakeOutline,
  mdiInformationVariant,
  mdiCheckOutline,
  mdiPhone,
  mdiMicrosoftOffice,
  mdiFileWord,
  mdiListStatus,
  mdiWrenchClock,
  mdiEmailOpenMultipleOutline,
  mdiBellRing,
  mdiNoteTextOutline,
} from '@mdi/js';
import { mapGetters } from 'vuex';
import { sortBy } from 'lodash';

export default {
  computed: {
    ...mapGetters('auth', ['isInRole']),
    menuItems() {
      const userSettings = [];
      let adminSettings = [];
      if (this.isInRole('Administrators')) {
        adminSettings = [{
          name: 'menu.industries', pathName: 'clients-ListIndustry', icon: mdiFactory, topMenu: false,
        }, {
          name: 'menu.jobListingStatuses', pathName: 'admin-ListJobListingStatus', icon: mdiListStatus, topMenu: false,
        }, {
          name: 'menu.eventLog', pathName: 'admin-EventLog', icon: mdiNoteTextOutline, topMenu: false,
        }, {
          name: 'menu.stages', pathName: 'tasks-Stage', icon: mdiFileTree, topMenu: false,
        }, {
          name: 'menu.reasons', pathName: 'tasks-ListReason', icon: mdiHeadSnowflakeOutline, topMenu: false,
        }, {
          name: 'menu.explanations', pathName: 'tasks-ListExplanation', icon: mdiInformationVariant, topMenu: false,
        }, {
          name: 'menu.checklists', pathName: 'tasks-Checklists', icon: mdiCheckOutline, topMenu: false,
        }, {
          name: 'menu.messagesExchange', pathName: 'admin-MessagesExchange', icon: mdiEmailOpenMultipleOutline, topMenu: false,
        }, {
          name: 'menu.mobileNumbers', pathName: 'candidates-ListTwilioNumber', icon: mdiPhone, topMenu: false,
        }, {
          name: 'menu.notifications', pathName: 'admin-Notifications', icon: mdiBellRing, topMenu: false,
        }, {
          name: 'menu.jobs', pathName: 'admin-Jobs', icon: mdiWrenchClock, topMenu: false,
        }, {
          name: 'menu.users', pathName: 'admin-Users', icon: mdiAccountSupervisor, topMenu: false,
        }, {
          name: 'menu.feedbackItems', pathName: 'admin-FeedbackItems', icon: mdiEmoticonHappyOutline, topMenu: false,
        }, {
          name: 'menu.teams', pathName: 'teams-View', icon: mdiMicrosoftOffice, topMenu: false,
        }, {
          name: 'menu.templates', pathName: 'clients-ListDocumentTemplate', icon: mdiFileWord, topMenu: false,
        }];
      }
      return sortBy([...userSettings, ...adminSettings], 'name');
    },
  },
};
