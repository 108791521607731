import axios from 'axios';

export default {
  namespaced: true,
  actions: {
    loadConfig({ commit }) {
      axios.get('/api/mailConfig').then((response) => {
        commit('config', response.data);
      });
    },
    saveConfig({ commit }, config) {
      axios.post('/api/mailConfig', config);
      commit('config', config);
    },
  },
  mutations: {
    config(state, payload) {
      state.config = payload;
    },
  },
  state: {
    config: undefined,
  },
};
