<template>
  <v-row>
    <v-col v-if="chartData.length > 1" :cols="isVertical ? '12' : '6'">
      <div class="text-subtitle-2 text-center">
        Reasons for Stopping
      </div>
      <GChart
        type="PieChart"
        :data="chartData"
        :options="chartOptions"
        :resizeDebounce="500"
      />
    </v-col>
    <v-col v-if="!hideExplanations && chartData2.length > 1" :cols="isVertical ? '12' : '6'">
      <div class="text-subtitle-2 text-center">
        Explanations
      </div>
      <GChart
        type="PieChart"
        :data="chartData2"
        :options="chartOptions2"
        :resizeDebounce="500"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { GChart } from 'vue-google-charts/legacy';
import { sortBy } from 'lodash';

export default {
  name: 'GraphJobListingIssues',
  props: {
    jobId: {
      type: String,
      default: 'job',
    },
    hideExplanations: {
      type: Boolean,
      default: false,
    },
    isVertical: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    GChart,
  },
  data() {
    return {
      explanations: [],
      reasons: [],
      chartOptions: {
        isStacked: true,
        colors: ['#1e0b4c', '#1e0ca9', '#7080f3', '#9467bd', '#f8cf59', '#2ca02c'],
        legend: { position: 'top', maxLines: 3 },
        annotations: {
          alwaysOutside: true, // This ensures labels are always outside the columns (optional)
          textStyle: {
            fontSize: 12, // You can adjust the font size
            auraColor: 'none', // Removes the background color around the text
            color: '#555', // You can set the text color
          },
          // This is necessary to actually show the annotations
          stem: {
            length: 0,
          },
        },
        // Enable the annotations for the series you want, assuming it's the first series (index 0)
        series: {
          0: {
            annotations: {
              textStyle: {
                fontSize: 12,
                color: '#000',
              },
            },
          },
        },
      },
      chartOptions2: {
        isStacked: true,
        legend: { position: 'top', maxLines: 3 },
        colors: ['#1e0b4c', '#1e0ca9', '#7080f3', '#9467bd', '#f8cf59', '#2ca02c'],
        annotations: {
          alwaysOutside: true, // This ensures labels are always outside the columns (optional)
          textStyle: {
            fontSize: 12, // You can adjust the font size
            auraColor: 'none', // Removes the background color around the text
            color: '#555', // You can set the text color
          },
          // This is necessary to actually show the annotations
          stem: {
            length: 0,
          },
        },
        // Enable the annotations for the series you want, assuming it's the first series (index 0)
        series: {
          0: {
            annotations: {
              textStyle: {
                fontSize: 12,
                color: '#000',
              },
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters('stages', ['orderedStages']),
    chartData() {
      const chartData = [['Reason', 'Count', { role: 'annotation' }]];
      this.reasons.forEach((item) => {
        chartData.push([item.name, item.count, item.count.toString()]);
      });
      return chartData;
    },
    chartData2() {
      const chartData = [['Explanation', 'Count', { role: 'annotation' }]];
      const sortedExplanations = sortBy(this.explanations, 'count');
      sortedExplanations.forEach((item) => {
        chartData.push([item.name, item.count, item.count.toString()]);
      });
      const emptyExplanations = this.explanations.filter((item) => item.count === 0);
      emptyExplanations.forEach((item) => {
        chartData.push([item.name, item.count, item.count.toString()]);
      });
      return chartData;
    },
  },
  async mounted() {
    await this.refresh();
    this.$root.$on('update:jobListingCandidate', this.refresh);
  },
  destroyed() {
    this.$root.$off('update:jobListingCandidate', this.refresh);
  },
  methods: {
    async loadExplanations() {
      const { data } = await this.$http.get(`/api/reports/explanations/${this.jobId}`);
      this.explanations = data;
    },
    async loadReasons() {
      const { data } = await this.$http.get(`/api/reports/reasons/${this.jobId}`);
      this.reasons = data;
    },
    async refresh() {
      await Promise.all([
        (async () => {
          await this.loadExplanations();
        })(),
        (async () => {
          await this.loadReasons();
        })(),
      ]);
    },
  },
};
</script>
