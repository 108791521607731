/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import { ref, computed } from 'vue';
import * as Msal from 'msal';

const baseScope = ['api://302098e9-1ec8-438d-b444-1f65d1352158/BrightFuture.Read'];

export const useAuth = () => {
  const config = {
    auth: {
      clientId: process.env.VUE_APP_AZURE_IDENTITY_CLIENT_ID,
      authority: `https://login.microsoftonline.com/${process.env.VUE_APP_AZURE_IDENTITY_TENANT_ID}`,
      redirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: 'localStorage',
    },
    storeAuthStateInCookie: 'true',
  };

  const userAgentApplication = new Msal.UserAgentApplication(config);

  const isAuthenticated = ref(false);
  const user = ref(null);

  async function getScopedAccessToken(scopes) {
    const tokenRequest = {
      scopes,
    };

    try {
      const tokenResponse = await userAgentApplication.acquireTokenSilent(tokenRequest);
      if (tokenResponse) {
        window.localStorage.setItem('access_token', tokenResponse.accessToken);
        return tokenResponse.accessToken;
      }
    } catch (error) {
      console.error(error);
      // acquireTokenSilent failed, fallback to acquireTokenRedirect
      try {
        tokenRequest.redirectUri = `${window.location.origin}/auth/login`;
        const tokenResponseFallback = await userAgentApplication.acquireTokenRedirect(tokenRequest);
        window.localStorage.setItem('access_token', tokenResponseFallback.accessToken);
        return tokenResponseFallback.accessToken;
      } catch (innerError) {
        console.error(error);
      }
    }
    console.warn('no token found, returning nothing');
    return null;
  }

  async function getAccessToken() {
    const tokenRequest = {
      scopes: baseScope,
      forceRefresh: false,
    };
    try {
      const tokenResponse = await userAgentApplication.acquireTokenSilent(tokenRequest);
      if (tokenResponse) {
        window.localStorage.setItem('access_token', tokenResponse.accessToken);
        return tokenResponse.accessToken;
      }
    } catch (error) {
      console.error(error);
      // acquireTokenSilent failed, fallback to acquireTokenRedirect
      try {
        tokenRequest.redirectUri = `${window.location.origin}/auth/login`;
        const tokenResponseFallback = await userAgentApplication.acquireTokenRedirect(tokenRequest);
        window.localStorage.setItem('access_token', tokenResponseFallback.accessToken);
        return tokenResponseFallback.accessToken;
      } catch (innerError) {
        console.error(error);
      }
    }
    console.warn('no token found, returning nothing');
    return null;
  }

  async function handleLoginRedirect() {
    console.debug('handle redirect');
    const account = await userAgentApplication.handleRedirectPromise();
    console.debug(account);
    if (account) {
      isAuthenticated.value = true;
      user.value = account;
      return true;
    }

    return false;
  }

  function login() {
    try {
      const loginRequest = {
        scopes: ['openid', 'profile', 'User.Read', 'offline_access', 'api://302098e9-1ec8-438d-b444-1f65d1352158/BrightFuture.Read Team.ReadBasic.All'],
        redirectUri: `${window.location.origin}/auth/login`,
      };
      userAgentApplication.loginRedirect(loginRequest);
      isAuthenticated.value = true;
      user.value = userAgentApplication.getAccount();
    } catch (error) {
      console.error(error);
    }
  }

  async function logout() {
    userAgentApplication.logout();
    isAuthenticated.value = false;
    user.value = null;
  }

  const accessToken = computed(async () => {
    if (userAgentApplication.getAccount()) {
      try {
        const response = await userAgentApplication.acquireTokenSilent({ scopes: config.scopes });
        return response.accessToken;
      } catch (error) {
        console.error(error);
        try {
          const response = await userAgentApplication.acquireTokenPopup({ scopes: config.scopes });
          return response.accessToken;
        } catch (innerError) {
          console.error(innerError);
        }
      }
    }
    return null;
  });

  return {
    isAuthenticated,
    user,
    login,
    logout,
    getAccessToken,
    getScopedAccessToken,
    accessToken,
    userAgentApplication,
    handleLoginRedirect,
  };
};
