import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('documentTemplateField', 'documentTemplateFields');

const customActions = {
  async LOAD_documentTemplateFields({ commit, rootGetters }, documentTemplateId) {
    const data = await odata.o(rootGetters)
      .get('documentTemplateFields')
      .query({ $filter: `IsDeleted eq false AND documentTemplateId eq ${documentTemplateId}` });
    commit('SET_documentTemplateFields', data);
    return data;
  },
};

export default { ...actions, ...customActions };
